
import { defineComponent } from '@nuxtjs/composition-api'
import { stripTags, truncate, setupTranslationPrefixer } from '~/assets/util'

export default defineComponent({
  props: {
    date: { type: String, required: false, default: undefined },
    url: { type: String, required: false, default: undefined },
    image: { type: Object, required: true },
    readMore: { type: Boolean, required: false, default: true },
    title: { type: String, required: true },
    summary: { type: String, required: false, default: undefined },
  },
  setup() {
    return { ...setupTranslationPrefixer('lid-carousel-slide-story'), stripTags, truncate }
  },
})
