
import get from 'lodash/get'
import { defineComponent, useStore } from '@nuxtjs/composition-api'
import { setupTranslationPrefixer } from '~/assets/util'

export default defineComponent({
  props: {
    page: { type: Object, required: true },
  },
  setup() {
    const store = useStore()
    const articleOverviewUrl = store.getters['global/articleOverview']?.url

    return {
      ...setupTranslationPrefixer('lid-landing-detail'),
      articleOverviewUrl,
      get,
    }
  },
})
