import { render, staticRenderFns } from "./LidLandingDetail.vue?vue&type=template&id=01f4e6cc&scoped=true"
import script from "./LidLandingDetail.vue?vue&type=script&lang=js"
export * from "./LidLandingDetail.vue?vue&type=script&lang=js"
import style0 from "./LidLandingDetail.vue?vue&type=style&index=0&id=01f4e6cc&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01f4e6cc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AtomButton: require('/home/userapp/components/Atom/Button.vue').default,MoleculeLidHeaderOverview: require('/home/userapp/components/Molecule/Lid/HeaderOverview.vue').default,AtomIconArrowRight: require('/home/userapp/components/Atom/Icon/IconArrowRight.vue').default,MoleculeLidHighlightBanner: require('/home/userapp/components/Molecule/Lid/HighlightBanner.vue').default,MoleculeCarouselLidCarouselSlideStory: require('/home/userapp/components/Molecule/Carousel/LidCarouselSlideStory.vue').default,OrganismLoaderFromUri: require('/home/userapp/components/Organism/Loader/LoaderFromUri.vue').default,MoleculeCarouselLidCarousel: require('/home/userapp/components/Molecule/Carousel/LidCarousel.vue').default,TemplateLidBase: require('/home/userapp/components/Template/Lid/LidBase.vue').default})
